import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
// import Carousel from "../components/carousel"
// import CategoryBar from "../components/category-bar"

import loadable from '@loadable/component'

const Carousel = loadable(() => import("../components/carousel"))
const CategoryBar = loadable(() => import("../components/category-bar"))

// const uikit = loadable(() => import('uikit/dist/css/uikit.css'))
// const UIkit = loadable(() => import('uikit'))

export const query = graphql`
query customCollectionsQuery($ItemList: [String]) {
  allJrmProducts(filter: {Reference: {in: $ItemList}}) {
    nodes {
      Id
      Name
      SDItemCategoryId
      AttractivePrice
      Description
      Slug
      PayOnline
      HidePrices
    }
  }

  allJrmCategories {
    nodes {
      Id
      Name
    }
  }
  
  allJrmFilteredCategories {
    nodes {
        Id
    }
  }



}
`



const CustomCollection = ({data, pageContext}) => {

  var FilteredCategories = []
    data.allJrmCategories.nodes.forEach((item, index) => {
      var exists = data.allJrmFilteredCategories.nodes.find(filteredItem => filteredItem.Id === item.Id)
      if(exists){
        FilteredCategories.push({
          Id: item.Id,
          Name: item.Name
        })
      }
    })

 


    return(
    <div>
      <Layout headerShow={false}>
        <SEO title={pageContext.name} />

        <CategoryBar data={FilteredCategories}></CategoryBar>



        {/* <div>{"asdf: "+pageContext.name}</div>

        <ul>
        {data.allJrmProducts.nodes.map((item, i) => {

            return (
                <li key={i}>{item.Description} - {item.Id}</li>
            )
        })}
        </ul> */}
  
        
  
        <div className="padding-top padding-bottom">
      
        <Carousel title={pageContext.name} data={data.allJrmProducts.nodes} optional={pageContext.CollectionDescription} url={pageContext.Link} ctaLabel={pageContext.ctaLabel} ctaUrl={pageContext.ctaUrl} titleLabel={pageContext.titleLabel}></Carousel>
        
        </div>
  
        
  
      </Layout>
  
  
      
    </div>
  )
    }
  
  export default CustomCollection